<template lang="">
  <v-container>
    <div>
      <h4 v-html="$t('toasts_title')"></h4>
      <v-data-table-server ref="table" striped hover :headers="fields2" :items="toasts" :fields="fields2" current-page="query.page"> </v-data-table-server>
    </div>
  </v-container>
</template>
<script lang="ts">
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'toasts',
  data() {
    return {
      fields: <DTSHeader[]>[{ key: this.$t('toast_table_header_message'), sortable: true }],
      fields2: [
        {
          text: this.$t('toast_table_header_message'),
          value: 'message',
          key: 'message',
          sortable: true,
          align: 'left'
        }
      ],
      toasts: this.$store.state.Toast.toasts
    }
  }
})
</script>
<style lang=""></style>
